import { Controller } from "@hotwired/stimulus"
import { DateRangePicker } from 'vanillajs-datepicker';

// Connects to data-controller="date-picker"
export default class extends Controller {
  // static targets = ['start', 'end']
  connect() {
    this.datepicker = new DateRangePicker(
      this.element,
      {
        language: 'zh-CN'
      })
  }
  disconnect(){
    this.datepicker.destroy()
  }
}
